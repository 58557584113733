import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../Comp/Home/navbar';
import Hero from '../Comp/Home/hero';
import Industries from '../Comp/Home/Industries/industries.jsx';
import Expertise from '../Comp/Home/Expertise/expertise.jsx';
import Newsletter from '../Comp/Home/Newsletter/newsletter.jsx';
import Award from '../Comp/Home/award';
import Collaborate from '../Comp/Home/collaborate';
import Footer from '../Comp/Home/footer';

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>Welcome to Cybadot - Leading Technology Solutions</title>
        <meta name="description" content="Cybadot offers leading technology solutions across various industries. Explore our services and expertise." />
        <meta name="keywords" content="Cybadot, Technology Solutions, Industries, Expertise" />
        <link rel="canonical" href="https://cybadot.com/" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "url": "https://cybadot.com/",
              "name": "Cybadot Home"
            }
          `}
        </script>
      </Helmet>
      <Navbar />
      <Hero />
      <Industries />
      <Expertise />
      <Award />
      <Newsletter />
      <Collaborate />
      <Footer />
    </div>
  );
};

export default Home;
